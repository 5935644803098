import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { AdminService } from "../../services/AdminService";
import moment from "moment";
import { ArrowRightOutlined, ClearOutlined } from "@ant-design/icons";

export default function MultipleCurrencyDonations() {
  const [multipleDonations, setMultipleDonations] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [projects, setProjects] = useState(null);
  const [veriMaster, setVeriMaster] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  useEffect(() => {
    AdminService.getMultipleDonations().then((response) => {
      console.log(response.data);
      setMultipleDonations(response.data.donations);
      setVeriMaster(response.data.donations);
      setProjects(response.data.projects);
    });
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => new Date(createdOn).toLocaleDateString(),
    },
    {
      title: "Full Name",
      dataIndex: "fullname",
    },
    {
      title: "Project Name",
      dataIndex: "project",
      render: (project) => project.substring(0, 20),
    },
    {
      title: "Email",
      dataIndex: "email",
    },

    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Message",
      dataIndex: "details",
    },

    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Currency",
      dataIndex: "currency",
    },
  ];
  const rangeChanged = (a) => {
    setDateRange(a);
  };
  const calculateTotalByCurrency = () => {
    const totals = {};
    multipleDonations?.forEach((item) => {
      if (totals[item.currency]) {
        totals[item.currency] += item.amount;
      } else {
        totals[item.currency] = item.amount;
      }
    });
    return totals;
  };
  const calculateTotalByProject = () => {
    const totals = {};
    multipleDonations?.forEach((item) => {
      if (totals[item.project]) {
        totals[item.project] += item.amount;
      } else {
        totals[item.project] = item.amount;
      }
    });
    return totals;
  };
  const totals = calculateTotalByCurrency();
  const totalsByProject = calculateTotalByProject();
  return (
    <div>
      <Divider orientation="left">Multiple Currency Donations</Divider>
      <Space>
        <DatePicker.RangePicker
          onChange={rangeChanged}
          value={dateRange}
          format={"DD/MM/YYYY"}
        />
        <Select
          value={selectedProject}
          onChange={setSelectedProject}
          className="w-72"
          allowClear
          placeholder="Filter by Project"
        >
          {projects?.map((project) => (
            <Select.Option value={project.id} key={project.id}>
              {project.name}
            </Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          icon={<ArrowRightOutlined />}
          onClick={() => {
            console.log(selectedProject);
            let filteredData = [...veriMaster];
            if (dateRange !== null && dateRange !== undefined) {
              filteredData = filteredData.filter((d) =>
                moment(d.createdOn).isBetween(dateRange[0], dateRange[1]),
              );
            }
            if (selectedProject !== null && selectedProject !== undefined) {
              filteredData = filteredData.filter(
                (d) => d.projectId === selectedProject,
              );
            }
            setMultipleDonations(filteredData);
          }}
        >
          Get Data
        </Button>
        <Button
          icon={<ClearOutlined />}
          onClick={() => {
            setDateRange(null);
            setSelectedProject(null);
            setMultipleDonations(veriMaster);
          }}
        >
          Clear
        </Button>
      </Space>
      <br />
      <br />
      <div>
        <Row gutter={32}>
          <Col span={12}>
            <Descriptions
              title="Total Amount by Currency"
              bordered
              size="small"
              column={1}
            >
              {Object.keys(totals).map((currency) => (
                <Descriptions.Item label={currency} key={currency}>
                  {totals[currency]}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </Col>
          <Col span={12}></Col>
        </Row>
      </div>
      <br />
      <Table
        dataSource={multipleDonations?.sort((a, b) => b.id - a.id)}
        columns={columns}
        size="small"
        bordered
        rowKey="id"
        pagination={false}
        loading={multipleDonations === null}
      />
    </div>
  );
}
