import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loginpage from "../components/login/Loginpage";

const LoginLayout = () => {
  return (
    <div>
      <Switch>
        <Route exact path={"/login"}>
          <Redirect to={"/login/loginpage"} />
        </Route>
        <Route path={"/login/loginpage"}>
          <Loginpage />
        </Route>
        {/*  <Route path={"/login/signuppage"}>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
          >
            <SignupPage />
          </GoogleReCaptchaProvider>
        </Route>
         <Route path={"/login/agreement"}>
        <Agreement />
      </Route>
      <Route path={"/login/forgetpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <ForgetPassword />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={"/login/newpassword"}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_reCaptchaKey}
        >
          <NewPassword />
        </GoogleReCaptchaProvider>
      </Route> */}
        <Route>
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
};

export default LoginLayout;
