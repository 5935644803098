import moment from "moment";
import { axios } from "../resources/HttpClient";

export const AdminService = {
  newProject: (values) => {
    return axios.post("/projects", { ...values });
  },

  getProjects: () => {
    return axios.get("/projects");
  },
  getFavoriteProjects: () => {
    return axios.get("/projects/favorites");
  },
  getProjectById: (id) => {
    return axios.get("/projects/" + id);
  },
  getProjectByIdForDonationsForm: (id) => {
    return axios.get("/projects/" + id + "/form");
  },

  getProjectByType: (id) => {
    return axios.get("/projects/type/" + id);
  },
  deleteProject: (id) => {
    return axios.delete("/projects/" + id);
  },
  editProject: (item) => {
    return axios.put("/projects/" + item.id, { ...item });
  },
  changeProjectActive: (id) => {
    return axios.put("/projects/active/" + id);
  },
  changeProjectFavorite: (id) => {
    return axios.put("/projects/favorite/" + id);
  },
  changeProjectFeatured: (id) => {
    return axios.put("/projects/feature/" + id);
  },
  getFeaturedProject: () => {
    return axios.get("/projects/feature");
  },
  signupDonor: (values) => {
    return axios.post("/validation/signup", { ...values });
  },
  checkLogin: (values) => {
    return axios.post("/validation", { ...values });
  },
  newSubscription: (values) => {
    return axios.post("/subscriptions", { ...values });
  },
  getSubscriptions: () => {
    return axios.get("/subscriptions");
  },
  deleteSubscription: (id) => {
    return axios.delete("/subscriptions/" + id);
  },
  newDonationStripe: (values) => {
    return axios.post("/donations/stripe", values);
  },
  newDonationBankTransfer: (values) => {
    return axios.post("/donations/banktransfer", values);
  },
  newDonationPaypalCreate: (values) => {
    return axios.post("/donations/paypal/create", values);
  },
  newDonationPaypalConfirm: (url, values) => {
    return axios.post(url.replace(/^\/api/, ""), values);
  },
  newDonation: (values) => {
    return axios.post("/donations", values);
  },
  newContact: (values) => {
    return axios.post("/contacts", { ...values });
  },
  getContacts: () => {
    return axios.get("/contacts");
  },
  deleteContact: (id) => {
    return axios.delete("/contacts/" + id);
  },
  getUserDonations: (values) => {
    return axios.post("/reports/donations", { ...values });
  },
  getQurbanData: (id) => {
    return axios.get("/qurbans/" + id);
  },
  getQurbanList: () => {
    return axios.get("/qurbans/list");
  },
  deleteQurban: (id) => {
    return axios.delete("/qurbans/" + id);
  },
  getQtyData: (id) => {
    return axios.get("/reports/qtyItemDetails/" + id);
  },
  deleteQtyItemDonation: (id) => {
    return axios.delete("/donations/qtyItemDonation/" + id);
  },
  getDonations: () => {
    return axios.get("/donations");
  },
  getDonationById: (id) => {
    return axios.get("/donations/" + id);
  },
  deletePart: (id) => {
    return axios.delete("/donations/part/" + id);
  },
  getMainReports: () => {
    return axios.get("/reports/maindash");
  },
  getProjectDonations: (id) => {
    return axios.get("/projects/" + id + "/donations");
  },
  getDonationsExcel: (values) => {
    var dates = [];
    if (values && values.length > 0) {
      dates.push(moment(values[0]).format());
      dates.push(moment(values[1]).format());
    }
    return axios.get("/donations/excel", {
      responseType: "blob",
      params: {
        start: dates[0],
        end: dates[1],
      },
    });
  },
  getProjectsPriority: () => {
    return axios.get("/projects/priority");
  },
  saveNewProjectPriorities: (value) => {
    return axios.post("/projects/priorities", { sira: value });
  },
  saveNewPriorities: (value) => {
    return axios.post("/news/priorities", { sira: value });
  },
  getLoginCode: (email) => {
    return axios.get("/validation/getpasscode?email=" + email);
  },
  checkPassCode: (values) => {
    return axios.get(
      "/validation/checkpasscode?email=" + values.email + "&code=" + values.code
    );
  },
  getInvoicePdf: (id) => {
    return axios.get("/invoices/" + id + ".pdf", { responseType: "blob" });
  },
  getApprovals: () => {
    return axios.get("/donations/approvals");
  },
  putApprovedPayment: (id) => {
    return axios.put("/donations/approved/" + id);
  },
  putCancelPayment: (id) => {
    return axios.put("/donations/cancelled/" + id);
  },
  putQurbansDeliver: (id, note) => {
    return axios.put("/qurbans/deliver/" + id + "?note=" + note);
  },
  getStats: () => {
    return axios.get("/reports/stats");
  },
  putStats: (values) => {
    return axios.put("/reports/stats", { ...values });
  },
  getOldData: () => {
    return axios.get("/archives");
  },
  newVolunteer: (c) => {
    return axios.post("/volunteers", c);
  },
  getVolunteers: () => {
    return axios.get("/volunteers");
  },
  newSlide: (c) => {
    return axios.post("/sliders", c);
  },
  getSliders: () => {
    return axios.get("/sliders");
  },
  deleteSlide: (id) => {
    return axios.delete("/sliders/" + id);
  },
  newNews: (values) => {
    return axios.post("/news", { ...values });
  },
  editNews: (values) => {
    return axios.put("/news/" + values.id, { ...values });
  },
  getNews: () => {
    return axios.get("/news");
  },
  getNewsHome: () => {
    return axios.get("/news/home");
  },
  deleteNews: (id) => {
    return axios.delete("/news/" + id);
  },
  changeNewsFavorite: (id) => {
    return axios.put("/news/favorite/" + id);
  },
  changeNewsActive: (id) => {
    return axios.put("/news/active/" + id);
  },
  getFavoriteNews: () => {
    return axios.get("/news/favorite");
  },
  getNewsById: (id) => {
    return axios.get("/news/" + id);
  },
  newEvent: (values) => {
    return axios.post("/events", { ...values });
  },
  getEvents: () => {
    return axios.get("/events");
  },
  editEvents: (values) => {
    return axios.put("/events/" + values.id, { ...values });
  },
  getEventById: (id) => {
    return axios.get("/events/" + id);
  },
  newRegisterMember: (values) => {
    return axios.post("/registers", { ...values });
  },
  deleteRegistrations: (id) => {
    return axios.delete("/registers/" + id);
  },
  getEventsList: () => {
    return axios.get("/events/list");
  },
  getEventRegs: (id) => {
    return axios.get("/events/" + id + "/registrations");
  },
  getEventsHome: () => {
    return axios.get("/events/home");
  },
  changeEventFavorite: (id) => {
    return axios.put("/events/favorite/" + id);
  },
  changeEventActive: (id) => {
    return axios.put("/events/active/" + id);
  },
  getNewsPriority: () => {
    return axios.get("/news/priority");
  },

  getEventsPriority: () => {
    return axios.get("/events/priority");
  },
  saveEventPriorities: (value) => {
    return axios.post("/events/priorities", { sira: value });
  },
  getMultipleDonations: () => {
    return axios.get("/currency");
  },
};
