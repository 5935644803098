import { DownloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Space, Table, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AdminService } from "../../services/AdminService";

const QurbanList = () => {
  const [qurbans, setQurbans] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [veriMaster, setVeriMaster] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [typeFilter, setTypeFilter] = useState({});
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getQurbanList().then((data) => {
        setVeriMaster(data.data);
        setFiltered(data.data);
        setQurbans(data.data);
        console.log(data.data);
      });
    }
  }, [initialRender]);
  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setTypeFilter(filters);
  };
  const rangeChanged = (a) => {
    setQurbans(filtered.filter((d) => moment(d.date).isBetween(a[0], a[1])));
    setFiltered(filtered.filter((d) => moment(d.date).isBetween(a[0], a[1])));

    setDateRange(a);
  };
  const headers = [
    { label: "Date", key: "date" },
    { label: "Donor", key: "donor" },
    { label: "On Behalf", key: "onbehalf" },
    { label: "Type", key: "type" },
    { label: "Price", key: "total" },
    { label: "Currency", key: "currency" },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD/MM/YYYY HH:mm"),
      sorter: (a, b) => {
        const timeA = moment(a.date, "HH:mm");
        const timeB = moment(b.date, "HH:mm");
        return timeA - timeB;
      },
    },
    {
      title: "Donor",
      dataIndex: "donor",
      key: "donor",
    },
    {
      title: "On Behalf",
      dataIndex: "onbehalf",
      key: "onbehalf",
      sorter: (a, b) => a.onbehalf.localeCompare(b.onbehalf),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filters: [
        {
          text: "Wajib",
          value: "Wajib",
        },
        {
          text: "Aqueeqah",
          value: "Aqueeqah",
        },
        {
          text: "Nafila",
          value: "Nafila",
        },
        {
          text: "Nazr",
          value: "Nazr",
        },
      ],
      onFilter: (value, record) => record.type === value,
      filterSearch: false,
      filteredValue: typeFilter.type || null,
    },
    {
      title: "Price",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => a.currency.localeCompare(b.currency),
    },
  ];
  function formatDates(dataArray) {
    return dataArray.map((item) => {
      const formattedDate = moment(item.date).format("YYYY-MM-DD HH:mm:ss");
      return {
        ...item,
        date: formattedDate,
      };
    });
  }
  const countTypes = () => {
    const typeCount = {
      Wajib: 0,
      Aqueeqah: 0,
      Nafila: 0,
      Nazr: 0,
    };

    filtered?.forEach((item) => {
      if (typeCount.hasOwnProperty(item.type)) {
        typeCount[item.type]++;
      }
    });

    return typeCount;
  };
  return (
    <div>
      <Divider orientation="left">Qurban Donations List</Divider>
      <Space>
        <DatePicker.RangePicker
          onChange={rangeChanged}
          value={dateRange}
          format={"DD/MM/YYYY"}
        />
        <Button
          onClick={() => {
            setDateRange(null);
            setQurbans(veriMaster);
            setFiltered(veriMaster);
            setTypeFilter({});
          }}
        >
          Clear
        </Button>
        {filtered && (
          <CSVLink
            className="ant-btn ant-btn-primary "
            data={formatDates(filtered)}
            headers={headers}
            filename="qurbans.csv"
          >
            <DownloadOutlined /> Download Filtered Data
          </CSVLink>
        )}
      </Space>
      <br />
      <br />
      {countTypes() && (
        <div className="mb-2">
          <Space align="baseline">
            <h3>Qurban Types:</h3>
            {Object.keys(countTypes()).map((type) => (
              <Tag color="blue" key={type}>
                {type}: {countTypes()[type]}
              </Tag>
            ))}
          </Space>
        </div>
      )}
      <Table
        dataSource={qurbans}
        columns={columns}
        bordered
        size="small"
        loading={qurbans === null}
        rowKey="id"
        pagination={false}
        onChange={(pagination, filters, sorter, extra) => {
          console.log("filtered:", extra.currentDataSource);
          handleChange(pagination, filters, sorter);
          setFiltered(extra.currentDataSource);
        }}
      />
    </div>
  );
};

export default QurbanList;
