import React from "react";
import "react-phone-number-input/style.css";
import "react-quill/dist/quill.snow.css";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.less";
import AdminLayout from "./layouts/AdminLayout";
import LoginLayout from "./layouts/LoginLayout";
import { AdminRoute } from "./resources/PrivateRoute";

function App() {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <AdminRoute path="/admin" component={AdminLayout} />
      <Route path="/login" component={LoginLayout} />

      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default App;
