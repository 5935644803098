import {
  BellOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DollarCircleOutlined,
  EuroOutlined,
  GiftOutlined,
  HeartOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  SlidersOutlined,
  VerticalAlignMiddleOutlined,
} from "@ant-design/icons";
import { Button, Col, Drawer, Layout, Menu, Row, Tag } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import Approvals from "../components/admin/Approvals";
import BannerSlider from "../components/admin/BannerSlider";
import CheckPayments from "../components/admin/CheckPayments";
import Contacts from "../components/admin/Contacts";
import DashboardPage from "../components/admin/DashboardPage";
import DonationDetails from "../components/admin/DonationDetails";
import DonationsDashboard from "../components/admin/DonationsDashboard";
import MultipleCurrencyDonations from "../components/admin/MultipleCurrencyDonations";
import ProjectDonations from "../components/admin/ProjectDonations";
import QtyDashboard from "../components/admin/QtyDashboard";
import QurbanDashboard from "../components/admin/QurbanDashboard";
import QurbanList from "../components/admin/QurbanList";
import Subscriptions from "../components/admin/Subscriptions";
import EditProject from "../components/admin/definitions/EditProject";
import Priority from "../components/admin/definitions/Priority";
import ProjectAdminDetails from "../components/admin/definitions/ProjectAdminDetails";
import Projects from "../components/admin/definitions/Projects";
import EventDetails from "../components/admin/events/EventDetails";
import EventEdit from "../components/admin/events/EventEdit";
import EventsAdmin from "../components/admin/events/EventsAdmin";
import PriorityEvents from "../components/admin/events/PriorityEvents";
import Registrations from "../components/admin/events/Registrations";
import NewsAdmin from "../components/admin/news/NewsAdmin";
import NewsDetails from "../components/admin/news/NewsDetails";
import NewsEdit from "../components/admin/news/NewsEdit";
import NewsPriority from "../components/admin/news/Priority";

const AdminLayout = () => {
  const { Header, Content, Footer, Sider } = Layout;
  const { lg } = useBreakpoint();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSider, setIsSider] = useState(false);
  const router = useHistory();

  const logOut = () => {
    localStorage.clear();
    router.push("/home");
  };

  useEffect(() => {
    setIsSider(lg ? false : true);
  }, [lg]);
  return (
    <Layout>
      <Header
        className="header-class shadow-sm"
        style={{
          position: lg ? "fixed" : "unset",
          zIndex: 111,
          width: "100%",
        }}
      >
        <Row justify="space-between" align="middle">
          {lg ? undefined : (
            <Button
              icon={isSider ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              size="large"
              onClick={() => setIsSider(!isSider)}
            />
          )}
          <Col>
            <img
              src={require("../assets/images/logo.svg").default}
              width="150"
              alt="logo"
              className="ml-4"
            />
          </Col>
          <Col>
            {lg ? (
              <Button onClick={logOut} type="primary" icon={<LogoutOutlined />}>
                Logout
              </Button>
            ) : (
              <Button
                icon={<MenuOutlined />}
                size="large"
                onClick={() => setIsCollapsed(true)}
              />
            )}
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}
          onCollapse={(collapsed, type) => {}}
          trigger={null}
          collapsible
          collapsed={isSider}
          theme="light"
          className={!lg ? "mobil-sider" : "desktop-sider"}
        >
          <div className="text-center mb-2 mt-4">
            <Tag color="blue">Admin Menu</Tag>
          </div>

          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={["dashboard"]}
            style={{ backgroundColor: "transparent" }}
          >
            <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="Project" icon={<ClusterOutlined />}>
              <Link to="/admin/projects">Projects</Link>
            </Menu.Item>
            <Menu.Item key="priority" icon={<VerticalAlignMiddleOutlined />}>
              <Link to="/admin/priority">Project Priorities</Link>
            </Menu.Item>
            <Menu.Item key="donations" icon={<DollarCircleOutlined />}>
              <Link to="/admin/donations">Donations</Link>
            </Menu.Item>
            <Menu.Item key="multiple" icon={<EuroOutlined />}>
              <Link to="/admin/multiple">Multiple Currency</Link>
            </Menu.Item>
            <Menu.Item key="qurbans" icon={<GiftOutlined />}>
              <Link to="/admin/qurbans">Qurbans</Link>
            </Menu.Item>
            <Menu.Item key="qurbalist" icon={<GiftOutlined />}>
              <Link to="/admin/qurbanlist">Qurban List</Link>
            </Menu.Item>
            <Menu.Item key="quantities" icon={<OrderedListOutlined />}>
              <Link to="/admin/quantities">Quantities</Link>
            </Menu.Item>
            <Menu.Item key="check" icon={<CheckCircleOutlined />}>
              <Link to="/admin/paymentcheck">Donor Check</Link>
            </Menu.Item>

            <Menu.Item key="subs" icon={<BellOutlined />}>
              <Link to="/admin/subscriptions">Subscriptions</Link>
            </Menu.Item>
            <Menu.Item key="bannerslider" icon={<SlidersOutlined />}>
              <Link to="/admin/bannerslider">Banner Slider</Link>
            </Menu.Item>
            <Menu.SubMenu
              key="definitions"
              icon={<NotificationOutlined />}
              title="News/Events"
            >
              <Menu.Item key="news" icon={<NotificationOutlined />}>
                <Link to="/admin/news">News</Link>
              </Menu.Item>
              <Menu.Item
                key="newspriority"
                icon={<VerticalAlignMiddleOutlined />}
              >
                <Link to="/admin/news/priority">News Priority</Link>
              </Menu.Item>
              <Menu.Item key="events" icon={<CalendarOutlined />}>
                <Link to="/admin/events">Events</Link>
              </Menu.Item>
              <Menu.Item
                key="eventspriority"
                icon={<VerticalAlignMiddleOutlined />}
              >
                <Link to="/admin/events/priority">Events Priority</Link>
              </Menu.Item>

              <Menu.Item key="registrations" icon={<CheckCircleOutlined />}>
                <Link to="/admin/registrations">Registrations</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="contact" icon={<MessageOutlined />}>
              <Link to="/admin/contacts">Contacts</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            marginTop: lg ? "60px" : undefined,
            padding: "10px",
            borderLeft: "1px solid #ddd",
          }}
        >
          <Drawer
            title={
              <img
                src={require("../assets/images/logo.svg").default}
                width="150"
                alt="logo"
              />
            }
            visible={isCollapsed}
            placement="right"
            closable={true}
            onClose={() => setIsCollapsed(false)}
          ></Drawer>
          <Switch>
            <Route path="/admin" exact>
              <Redirect to="/admin/dashboard" />
            </Route>
            <Route path="/admin/dashboard" component={DashboardPage} />

            <Route path="/admin/subscriptions" component={Subscriptions} />
            <Route
              path="/admin/projects/details"
              component={ProjectAdminDetails}
            />
            <Route path="/admin/projects/edit" component={EditProject} />
            <Route
              path="/admin/projects/:id/donations"
              component={ProjectDonations}
            />
            <Route path="/admin/projects" component={Projects} />
            <Route
              path="/admin/multiple"
              component={MultipleCurrencyDonations}
            />
            <Route path="/admin/contacts" component={Contacts} />
            <Route path="/admin/qurbans" component={QurbanDashboard} />
            <Route path="/admin/qurbanlist" component={QurbanList} />
            <Route path="/admin/quantities" component={QtyDashboard} />
            <Route path="/admin/donations/:id" component={DonationDetails} />
            <Route path="/admin/donations" component={DonationsDashboard} />
            <Route path="/admin/paymentcheck" component={CheckPayments} />
            <Route path="/admin/priority" component={Priority} />
            <Route path="/admin/approval" component={Approvals} />
            <Route path="/admin/bannerslider" component={BannerSlider} />
            <Route path="/admin/news/:id/details" component={NewsDetails} />
            <Route path="/admin/news/:id/edit" component={NewsEdit} />
            <Route path="/admin/news/priority" component={NewsPriority} />
            <Route path="/admin/news" component={NewsAdmin} />
            <Route path="/admin/events/:id/details" component={EventDetails} />
            <Route path="/admin/events/:id/edit" component={EventEdit} />
            <Route path="/admin/events/priority" component={PriorityEvents} />
            <Route path="/admin/events" component={EventsAdmin} />
            <Route path="/admin/registrations" component={Registrations} />
            <Route>
              <Redirect to="/admin" />
            </Route>
          </Switch>
        </Content>
      </Layout>
      <Footer
        className="footer"
        style={{ textAlign: "center", fontSize: "13px" }}
      >
        © 2023 All Rights Reserved | ALF Relief Fund | Developed by{" "}
        <a href="https://softmile.com" target="blank_">
          Softmile
        </a>{" "}
        with <HeartOutlined /> React
      </Footer>
    </Layout>
  );
};

export default AdminLayout;
