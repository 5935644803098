import {
  ClearOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Switch,
  Upload,
  message,
  notification,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import { GlobalVariables } from "../../../resources/GlobalVariables";
import { AdminService } from "../../../services/AdminService";
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const NewProject = ({ sir, panel }) => {
  const [form] = Form.useForm();
  const [quillValue, setQuillValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [ptype, setPtype] = useState(null);
  const [quillValueTr, setQuillValueTr] = useState("");
  const { Option } = Select;

  const switchChange = (a) => {
    setIsFavorite(a);
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ fontSize: "26px" }} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleChangeImage = ({ file, fileList }) => {
    setFileList(fileList);
  };
  const handleChange = ({ file, fileList }) => {
    setFileList2(fileList);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const onFinish = (values) => {
    if (fileList.length === 0) {
      notification.error({
        message: "Error!",
        description: "You must be save banner image",
      });
      return;
    }
    if (quillValue === "") {
      notification.error({
        message: "Error!",
        description: "Please type project details",
      });
      return;
    }

    const imajlar = [];
    imajlar.push(fileList[0].response.url);
    if (fileList2.length > 0) {
      fileList2.forEach((i) => {
        imajlar.push(i.response.url);
      });
    }

    const req = {
      description: values.description,
      name: values.name,
      nameTr: values.nameTr,
      descriptionTr: values.descriptionTr,
      startDate: moment(values.startDate).format(),
      endDate: moment(values.endDate).format(),
      details: quillValue,
      detailsTr: quillValueTr,
      isActive: true,
      isFavorite: values.isFavorite,
      scopeId: values.scopeId,
      images: imajlar,
      videos: values.videos,
      intervals: values.interval,
      pType: values.pType,
      qurbanPrice: values.qurbanPrice,
      qurbanPriceTRY: values.qurbanPriceTRY,
      qurbanPriceUSD: values.qurbanPriceUSD,
      qurbanPriceEUR: values.qurbanPriceEUR,
      qurbanPriceGBP: values.qurbanPriceGBP,
      qurbanPriceCAD: values.qurbanPriceCAD,
      pTotalPrice: values.pTotalPrice,
      pMeterPrice: values.pMeterPrice,
      qtyItems: values.qtyItems,
    };
    AdminService.newProject(req).then((data) => {
      notification.success({
        message: "Success",
        description: "Project added successfully",
      });
      onReset();
      panel(false);
      sir(true);
    });
  };
  const onReset = () => {
    form.resetFields();
    setFileList([]);
    setFileList2([]);
    quillChange("");
  };
  const quillChange = (e) => {
    setQuillValue(e);
  };
  const quillChangeTr = (e) => {
    setQuillValueTr(e);
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ align: [] }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 18 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 18, offset: 6 },
    },
  };
  const ptypeChanged = (p) => {
    setPtype(p);
  };
  const beforeUpload = (file) => {
    const isPDF = file.type === "application/pdf";
    const isJPG = file.type === "image/jpeg";
    const isPNG = file.type === "image/png";
    if (!isPDF && !isJPG && !isPNG) {
      message.error("You can upload only image or pdf file");
    }
    const isLt1M = file.size / 1024 / 1024 < 2;
    if (!isLt1M) {
      message.error("File size too long!");
    }
    return (isPDF || isJPG || isPNG) && isLt1M;
  };
  return (
    <div>
      <Divider orientation="left">Add new project</Divider>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        {...GlobalVariables.formLayout}
        className="form-border"
        initialValues={{ isFavorite: false, videos: [], interval: [1] }}
      >
        <Form.Item
          name="name"
          label="Project Name"
          rules={[{ required: true, message: "Project name is required" }]}
        >
          <Input placeholder="Type new project name" />
        </Form.Item>
        <Form.Item
          name="nameTr"
          label="Proje Adı (Türkçe)"
          rules={[{ required: true, message: "Proje adi gereklidir" }]}
        >
          <Input placeholder="Proje adını yazınız  " />
        </Form.Item>
        <Form.Item
          name="description"
          label="Project Description"
          rules={[
            { required: true, message: "Project description is required" },
          ]}
        >
          <Input placeholder="Type project short description" />
        </Form.Item>
        <Form.Item
          name="descriptionTr"
          label="Proje Kısa Açıklama (Türkçe)"
          rules={[{ required: true, message: "Kisa aciklama gereklidir " }]}
        >
          <Input placeholder="Kısa açıklama yazınız" />
        </Form.Item>
        <Form.Item
          name="scopeId"
          label="Project Scope"
          rules={[{ required: true, message: "Scope is required" }]}
        >
          <Select style={{ width: 220 }} placeholder="Select scope">
            {GlobalVariables.scopes.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="pType"
          label="Project Type"
          rules={[{ required: true, message: "Type is required" }]}
        >
          <Select
            style={{ width: 220 }}
            placeholder="Select Project Type"
            onChange={ptypeChanged}
          >
            {GlobalVariables.projectTypes.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {ptype === 1 && (
          <>
            <Form.Item
              label="Qurban Price (AUD)"
              name="qurbanPrice"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `$${value}`} />
            </Form.Item>

            <Form.Item
              label="Qurban Price (TRY)"
              name="qurbanPriceTRY"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `₺${value}`} />
            </Form.Item>
            <Form.Item
              label="Qurban Price (USD)"
              name="qurbanPriceUSD"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `$${value}`} />
            </Form.Item>
            <Form.Item
              label="Qurban Price (EUR)"
              name="qurbanPriceEUR"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `€${value}`} />
            </Form.Item>
            <Form.Item
              label="Qurban Price (GBP)"
              name="qurbanPriceGBP"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `£${value}`} />
            </Form.Item>
            <Form.Item
              label="Qurban Price (CAD)"
              name="qurbanPriceCAD"
              rules={[{ required: true, message: "Missing per price" }]}
            >
              <InputNumber formatter={(value) => `$${value}`} />
            </Form.Item>
          </>
        )}
        {ptype === 3 && (
          <>
            <Form.Item
              label="Project Total Price"
              name="pTotalPrice"
              rules={[{ required: true, message: "Missing total price" }]}
            >
              <InputNumber formatter={(value) => `$${value}`} />
            </Form.Item>
            <Form.Item
              label="Project Meter Price"
              name="pMeterPrice"
              rules={[{ required: true, message: "Missing total price" }]}
            >
              <InputNumber formatter={(value) => `$${value}`} />
            </Form.Item>
          </>
        )}
        {ptype === 2 && (
          <Form.Item {...GlobalVariables.tailLayout}>
            <Form.List name="qtyItems">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <div key={field.key}>
                      <Space key={field.key} align="baseline">
                        <Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "name"]}
                            fieldKey={[field.fieldKey, "name"]}
                            rules={[
                              { required: true, message: "Missing name" },
                            ]}
                          >
                            <Input placeholder="Donation Name" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "nameTr"]}
                            fieldKey={[field.fieldKey, "nameTr"]}
                            rules={[
                              { required: true, message: "Missing name" },
                            ]}
                          >
                            <Input placeholder="Türkçe Adı" />
                          </Form.Item>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          label="Price"
                          name={[field.name, "price"]}
                          fieldKey={[field.fieldKey, "price"]}
                          rules={[{ required: true, message: "Missing price" }]}
                        >
                          <InputNumber formatter={(value) => `$${value}`} />
                        </Form.Item>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      style={{ maxWidth: "600px" }}
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Item
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
        {/* <Form.Item name="interval" label="Payment Interval" required>
          <Select
            mode="multiple"
            style={{ width: "220px" }}
            placeholder="Please select interval"
          >
            <Option value={1} disabled>
              One Off
            </Option>
            <Option value={2}>Daily</Option>
            <Option value={3}>Weekly</Option>
            <Option value={4}>Monthly</Option>
            <Option value={5}>Annual</Option>
          </Select>
        </Form.Item> */}
        {/* <Form.Item
          name="startDate"
          label="Start Date"
          rules={[{ required: true, message: "Start date required" }]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
        <Form.Item
          name="endDate"
          label="End Date"
          rules={[{ required: true, message: "End date required" }]}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item> */}
        <Form.Item
          label="Project Details"
          rules={[{ required: true, message: "Details required" }]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={(e) => quillChange(e)}
            placeholder="Type project long details"
          />
        </Form.Item>
        <Form.Item
          label="Proje Detayları (Türkçe)"
          rules={[{ required: true, message: "Detaylar Gereklidir" }]}
        >
          <ReactQuill
            modules={modules}
            theme="snow"
            onChange={(e) => quillChangeTr(e)}
            placeholder="Proje detaylarını yazınız"
          />
        </Form.Item>
        <Form.Item label="Banner Image (required)">
          <div className="clearfix">
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList}
              onChange={handleChangeImage}
              onPreview={handlePreview}
              beforeUpload={beforeUpload}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </div>
          <small>Aspect Ratio must be 4:3</small>
        </Form.Item>
        <Form.Item label="Gallery Images (optional)">
          <>
            <Upload
              action={process.env.REACT_APP_API_BASEURL + "upload"}
              headers={{
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              }}
              listType="picture-card"
              fileList={fileList2}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </>
        </Form.Item>
        <Form.List name="videos">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "Video Links" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input video link",
                      },
                      { type: "url", message: "Invalid url" },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="Paste link here"
                      style={{ width: "60%" }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item {...GlobalVariables.tailLayout}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  Add Video Link (optional)
                </Button>

                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="Is Favorite ?" name="isFavorite">
          <Switch checked={isFavorite} onChange={switchChange} />
        </Form.Item>
        <Form.Item {...GlobalVariables.tailLayout}>
          <Space>
            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              Save
            </Button>
            <Button htmlType="reset" icon={<ClearOutlined />}>
              Reset
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewProject;
