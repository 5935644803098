import { Button, Checkbox, Form, Input, Space, notification } from "antd";

import React, { useState } from "react";
import { AdminService } from "../../../services/AdminService";

const RegForm = ({ smv, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    values = { ...values, eventId: eventId };
    AdminService.newRegisterMember(values)
      .then((data) => {
        notification.success({
          message: "Success",
          description: "Registration completed successfully",
        });
        setLoading(false);
        onReset();
        smv(false);
      })
      .catch(() => {
        setLoading(false);
        onReset();
        smv(false);
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        layout="vertical"
        initialValues={{ subs: true }}
      >
        <Form.Item
          name="name"
          label="Your Name"
          rules={[{ required: true, message: "Name required" }]}
        >
          <Input placeholder="Please type your name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Your E-mail"
          rules={[{ required: true, message: "E-mail required" }]}
        >
          <Input
            placeholder="Please type your email"
            type="email"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item name="phone" label="Your Phone">
          <Input placeholder="Please type your phone" />
        </Form.Item>
        <Form.Item name="subs" valuePropName="checked">
          <Checkbox>Add my mail to newsletter list</Checkbox>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Register
            </Button>
            <Button type="default" htmlType="reset">
              Clear
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegForm;
